import { jsx } from 'theme-ui';
import Helmet from 'react-helmet';
import NavMenu from '../components/NavMenu';
import ProductionOnly from '../components/ProductionOnly';

/** @jsx jsx */

export default function Layout({ children }) {
  return (
    <div sx={{ margin: `2rem auto`, maxWidth: 720, px: 1 }}>
      <ProductionOnly>
        <Helmet>
          <script async defer data-domain="pabl.xyz" src="https://plausible.io/js/plausible.js" />
        </Helmet>
      </ProductionOnly>
      <NavMenu />
      {children}
    </div>
  )
}
