import { Link } from "gatsby"
/** @jsx jsx */
import { jsx } from 'theme-ui';

const NavLink = ({ to, text }) => (
  <Link
    to={to}
    activeClassName='active'
    sx={{
      textDecoration: 'none',
      fontFamily: 'heading',
      fontSize: [2, 2, 2],
      color: 'text',
      transition: 'all 0.5s;',
      mx: 1,
      px: 2,
      py: 2,
      borderRadius: '2px',
      '&.active': {
        backgroundColor: 'muted',
      },
      '&:hover': {
        backgroundColor: 'muted',
      },
    }}
  >
    {text}
  </Link>
)

// TODO: config driven?
const config = [{
  to: '/posts',
  label: 'posts'
}, {
  to: '/now',
  label: 'now'
}, {
  to: '/projects',
  label: 'projects'
}, {
  to: '/contact',
  label: 'contact'
}]

export default function NavMenu() {
  return (
    <header
      sx={{
        display: 'grid',
        gridTemplateRows: ['1fr 1fr', '1fr'],
        gridTemplateColumns: '2fr 3fr',
        justifyItems: ['center', 'start'],
        alignItems: 'center',
        marginBottom: 4
      }}
    >
      <Link
        to='/'
        sx={{
          textDecoration: 'none',
          fontFamily: 'heading',
          color: 'primary',
          fontSize: 4,
          fontWeight: 'bold',
          marginBottom: [4, 0],
          gridColumn: ['1 / 5', '1 / 2'],
          position: 'relative'
        }}
      >
        [cool key bytes]
        {' '}
        <span sx={{
          position: 'absolute',
          top: '95%',
          right: '0%',
          fontSize: 1,
          color: 'text'
          
        }}
        >
          - just a name 🍪 🍪 🍪
        </span>
      </Link>
      <div sx={{ display: 'flex', width: '100%', justifyContent: 'space-around', gridColumn: ['1 / -1', 2] }}>
        {
          config.map(({ to, label }) =>
            <NavLink key={to} to={to} text={label} />
          )
        }
      </div>

    </header>
  );
}